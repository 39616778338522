import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchComprehensiveProductDetails } from '../../services/productService';
import './ProductDetail.css';

const ProductDetail = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const data = await fetchComprehensiveProductDetails(productId);
        setProduct(data);
        setSelectedImage(data.assets[0]?.image_path || '');
      } catch (err) {
        console.error('Error fetching product details:', err);
        setError('Failed to fetch product details.');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  if (loading) {
    return <div className="product-detail-loading">Loading...</div>;
  }

  if (error) {
    return <div className="product-detail-error">{error}</div>;
  }

  if (!product) {
    return <div className="product-detail-not-found">Product not found.</div>;
  }

  const handleThumbnailClick = (imagePath) => {
    setSelectedImage(imagePath);
  };

  const toggleDescription = () => {
    console.log('Toggle description clicked');
    setShowFullDescription(!showFullDescription);
  };

  return (
    <div className="product-detail">
      <div className="product-detail-header">
        <h1 className="product-title">{product.name}</h1>
        <p className="product-price">
          {product.price ? `$${product.price.toLocaleString('en-US')} ARS` : 'Price not available'}
        </p>
      </div>

      <hr className="divider" />

      <div className="product-detail-body">
        <div className="product-image-section">
          <img
            src={selectedImage}
            alt={product.name}
            className="product-main-image"
          />
          <div className="product-thumbnails">
            {product.assets.map((asset) => (
              <img
                key={asset.id}
                src={asset.image_path}
                alt={`${product.name} - ${asset.angle || 'Thumbnail'}`}
                className={`thumbnail ${selectedImage === asset.image_path ? 'selected' : ''}`}
                onClick={() => handleThumbnailClick(asset.image_path)}
                onError={(e) => {
                  e.target.src = `${process.env.REACT_APP_S3_BASE_URL}/assets/products/default.jpg`;
                }}
              />
            ))}
          </div>
        </div>

        <div className="product-info-section">
          <p><strong>Condition:</strong> {product.condition}</p>
          <p><strong>Color:</strong> {product.color}</p>

          <div className="product-description">
            <p>
              {product.description
                ? showFullDescription
                  ? product.description
                  : product.description.substring(0, 150) + (product.description.length > 150 ? '...' : '')
                : 'No description available.'}
            </p>
            {product.description && product.description.length > 150 && (
              <button
                className="see-more-button"
                onClick={toggleDescription}
              >
                {showFullDescription ? 'See less' : 'See more'}
              </button>
            )}
          </div>

          <hr className="divider" />

          <p><strong>Categories:</strong> {product.categories.map(cat => cat.name).join(', ')}</p>

          {Object.entries(product.attributes).map(([key, value]) => (
            <p key={key}><strong>{key}:</strong> {value || 'N/A'}</p>
          ))}
        </div>
      </div>

      <hr className="divider" />

      <div className="product-detail-footer">
        <a
          href={`https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=Hello,%20I'm%20interested%20in%20purchasing%20${encodeURIComponent(product.name)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button"
        >
          <img
            src="/assets/whatsapp/ChatOnWhatsAppButton/WhatsAppButtonGreenSmall.svg"
            alt="Chat on WhatsApp"
            className="whatsapp-icon"
          />
          Chat on WhatsApp
        </a>
      </div>
    </div>
  );
};

export default ProductDetail;
