import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../context/CartContext"; // Import Cart Context
import "./ProductCard.css";

const ProductCard = ({ product }) => {
  const navigate = useNavigate();
  const { dispatch } = useCart(); // Access dispatch function from Cart Context
  const defaultImage = `${process.env.REACT_APP_S3_BASE_URL}/assets/products/default.jpg`;

  const [mainImage, setMainImage] = useState(defaultImage);
  const [availableImages, setAvailableImages] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    if (product && product.assets && product.assets.length > 0) {
      const primaryAsset = product.assets.find((asset) => asset.is_primary);
      const fallbackImage = product.assets[0]?.image_path || defaultImage;
      setMainImage(primaryAsset ? primaryAsset.image_path : fallbackImage);
      const otherAssets = product.assets.filter((asset) => !asset.is_primary);
      setAvailableImages(primaryAsset ? [primaryAsset, ...otherAssets] : product.assets);
    } else {
      setAvailableImages([]);
      setMainImage(defaultImage);
    }
  }, [product]);

  const handleCardClick = () => {
    navigate(`/product/${product.id}`);
  };

  const handleAddToCart = () => {
    dispatch({
      type: "ADD_ITEM",
      payload: {
        id: product.id,
        name: product.name,
        price: product.adjusted_selling_price_ars || product.price,
        image: mainImage,
        quantity: 1,
      },
    });
  };

  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
  const whatsappMessage = `Hello, I'm interested in purchasing ${product?.name}.`;
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;

  const price =
    product?.adjusted_selling_price_ars != null
      ? product.adjusted_selling_price_ars
      : product?.price;

  return (
    <div className="product-card">
      <div className="product-card-image-container" onClick={handleCardClick}>
        <img
          src={mainImage}
          alt={product?.name || "Product Image"}
          className="product-card-main-image"
          onError={(e) => {
            const fallbackImage = availableImages[0]?.image_path || defaultImage;
            e.target.src = fallbackImage;
            setMainImage(fallbackImage);
          }}
        />
      </div>
      {availableImages.length > 1 && (
        <div className="product-card-thumbnail-gallery">
          {availableImages.map((image, index) => (
            <img
              key={index}
              src={image.image_path}
              alt={`${product?.name || "Product"} - ${image.angle || "Image"}`}
              className="product-card-thumbnail-image"
              onClick={(e) => {
                e.stopPropagation();
                setMainImage(image.image_path);
              }}
              onError={(e) => {
                e.target.src = defaultImage;
              }}
            />
          ))}
        </div>
      )}
      <div className="product-card-details">
        <h3 onClick={handleCardClick}>{product?.name}</h3>
        {price != null ? (
          <p className="product-card-price">
            ${price.toLocaleString("en-US")} ARS
          </p>
        ) : (
          <p className="product-card-price">Price not available</p>
        )}
        <p>
          <strong>Condition:</strong> {product?.condition || "N/A"}
        </p>
        <p>
          <strong>Color:</strong> {product?.color || "N/A"}
        </p>
        <div className="product-card-description-container">
          <p
            className={`product-card-description ${
              showFullDescription ? "expanded" : ""
            }`}
          >
            {product?.description || "No description available."}
          </p>
          {product?.description && product.description.length > 100 && (
            <span
              className="product-card-see-more"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullDescription(!showFullDescription);
              }}
            >
              {showFullDescription ? "See less" : "See more"}
            </span>
          )}
        </div>
        <p className="product-card-id">
          <strong>ID:</strong> {product?.id || "N/A"}
        </p>

        {/* Add to Cart Button */}
        <button
          className="product-card-add-to-cart-button"
          onClick={(e) => {
            e.stopPropagation();
            handleAddToCart();
          }}
        >
          Add to Cart
        </button>

        {/* WhatsApp Button */}
        <a
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          className="product-card-whatsapp-button"
          onClick={(e) => e.stopPropagation()}
        >
          <img
            src="/assets/whatsapp/ChatOnWhatsAppButton/WhatsAppButtonGreenSmall.svg"
            alt="Chat on WhatsApp"
            className="product-card-whatsapp-icon"
          />
        </a>
      </div>
    </div>
  );
};

export default ProductCard;
