import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CategoryCard.css';

const CategoryCard = ({ category }) => {
  const navigate = useNavigate();

  // Dynamically construct the S3 URL using the environment variable
  const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL || 'https://pablo-arbitrage.s3.us-east-2.amazonaws.com';
  const imageUrl = `${S3_BASE_URL}/assets/category/${category.id}/${category.id}.png`;

  const handleCardClick = () => {
    // Navigate to ProductPage with category ID in the query parameter
    navigate(`/products?category=${category.id}`);
  };

  return (
    <div className="category-card" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
      <img
        src={imageUrl}
        alt={category.name || 'Unknown Category'}
        className="category-card-image"
        onError={(e) => {
          e.target.src = '/assets/default-category.png'; // Fallback image
        }}
      />
      <p className="category-card-name">{category.name || 'Unknown Category'}</p>
    </div>
  );
};

export default CategoryCard;
