import React from 'react';
import { Link } from 'react-router-dom';
import SearchBar from '../search/SearchBar';
import CartIcon from '../cart/CartIcon'; // Corrected import path
import './Header.css';

const Header = () => {
  // Dynamically build the URL for the company logo
  const companyLogoUrl = `${process.env.REACT_APP_S3_BASE_URL}/assets/company/company_logo.png`;

  return (
    <header className="header">
      <div className="header-logo">
        <Link to="/">
          <img
            src={companyLogoUrl}
            alt="Logo"
            onError={(e) => {
              // Fallback to a local default logo if the S3 logo fails to load
              e.target.src = '/assets/images/company/default-logo.png';
            }}
          />
        </Link>
      </div>
      <SearchBar />
      <CartIcon /> {/* Add the CartIcon component */}
    </header>
  );
};

export default Header;
