import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Utility to get a valid token (auth or guest)
const getAuthToken = async () => {
  let token = localStorage.getItem('accessToken');
  let guestToken = localStorage.getItem('guestToken');

  if (!token && !guestToken) {
    console.warn("No auth or guest token found. Attempting to retrieve guest token...");
    try {
      const response = await axios.get(`${BASE_URL}/auth/guest-token`);
      guestToken = response.data.access_token;
      localStorage.setItem('guestToken', guestToken);
      console.log("Guest token retrieved and saved.");
    } catch (error) {
      console.error("Failed to retrieve guest token:", error);
      return null;
    }
  }

  return token || guestToken; // Return the available token
};

// Generic Axios request wrapper
const makeRequest = async (method, endpoint, data = null, params = null) => {
  try {
    const token = await getAuthToken();
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    const response = await axios({
      method,
      url: `${BASE_URL}${endpoint}`,
      headers,
      data,
      params,
    });

    console.log(`Response from ${endpoint}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error during request to ${endpoint}:`, error);
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    }
    throw error; // Rethrow error for handling in the caller
  }
};

// Service Methods

/**
 * Synchronizes the local cart with the backend.
 * @param {Array} items - Array of cart items to sync.
 * @returns {Promise} Axios response promise.
 */
export const syncCart = async (items) => {
  return await makeRequest('post', '/api/cart/sync', { items });
};

/**
 * Fetches the current cart from the backend.
 * @returns {Promise} Axios response promise.
 */
export const fetchCart = async () => {
  return await makeRequest('get', '/api/cart');
};

/**
 * Adds an item to the cart.
 * @param {string} productId - The ID of the product to add.
 * @param {number} quantity - Quantity of the product to add.
 * @returns {Promise} Axios response promise.
 */
export const addItemToCart = async (productId, quantity = 1) => {
  return await makeRequest('post', '/api/cart/add', { product_id: productId, quantity });
};

/**
 * Removes an item from the cart.
 * @param {string} productId - The ID of the product to remove.
 * @returns {Promise} Axios response promise.
 */
export const removeItemFromCart = async (productId) => {
  return await makeRequest('delete', `/api/cart/remove/${productId}`);
};

/**
 * Updates the quantity of a cart item.
 * @param {string} productId - The ID of the product.
 * @param {number} quantity - New quantity of the product.
 * @returns {Promise} Axios response promise.
 */
export const updateItemQuantity = async (productId, quantity) => {
  return await makeRequest('put', `/api/cart/update/${productId}`, { quantity });
};

/**
 * Clears the cart for the current user or session.
 * @returns {Promise} Axios response promise.
 */
export const clearCart = async () => {
  return await makeRequest('post', '/api/cart/clear');
};
