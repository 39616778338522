import React, { useState, useEffect } from 'react';
import './CookieBanner.css';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', JSON.stringify({ essential: true, analytics: true, marketing: true }));
    setShowBanner(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', JSON.stringify({ essential: true, analytics: false, marketing: false }));
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div className="cookie-banner">
        <p>
          We use cookies to improve your experience on our site. By continuing, you accept our use of cookies.
          <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">Learn more</a>.
        </p>
        <button onClick={handleAccept}>Accept All</button>
        <button onClick={handleReject}>Reject Non-Essential</button>
      </div>
    )
  );
};

export default CookieBanner;
