import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchFilteredProductsByCategory } from '../services/productService';
import createSocket from '../services/socket'; // Dynamic socket creator for real-time updates
import ProductCard from '../components/product/ProductCard';
import Header from '../components/layout/header/Header'; // Import the Header component
import './ProductPage.css';

const ProductPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get('category'); // Extract category ID from URL

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  // **Fetch Products Function**
  const loadProducts = useCallback(async () => {
    if (!categoryId) return;

    try {
      setLoading(true);
      const productData = await fetchFilteredProductsByCategory({ categoryId });
      setProducts(productData.products);
    } catch (error) {
      console.error('Error loading products:', error);
    } finally {
      setLoading(false);
    }
  }, [categoryId]);

  // **Handle Real-Time Updates**
  const handleRealTimeUpdate = useCallback((update) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === update.id ? { ...product, ...update } : product
      )
    );
  }, []);

  // **Effect to Load Products**
  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  // **Socket Connection for Real-Time Updates**
  useEffect(() => {
    const productSocket = createSocket('/products');

    productSocket.on('product_update', handleRealTimeUpdate);

    return () => {
      productSocket.off('product_update', handleRealTimeUpdate);
      productSocket.disconnect();
    };
  }, [handleRealTimeUpdate]);

  return (
    <div className="product-page">
      <Header /> {/* Add the Header component here */}
      {loading ? (
        <p>Loading products...</p>
      ) : products.length > 0 ? (
        <div className="product-list">
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      ) : (
        <p>No products found for this category.</p>
      )}
    </div>
  );
};

export default ProductPage;
