import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'antd';
import { fetchProductPricingDetails } from '../../services/pricingService';

const PriceHistoryModal = ({ visible, onCancel, productId }) => {
  const [priceHistory, setPriceHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (visible) {
      loadPriceHistory();
    }
  }, [visible]);

  const loadPriceHistory = async () => {
    try {
      setLoading(true);
      const data = await fetchProductPricingDetails(productId);
      setPriceHistory(data.history); // Ensure the backend provides a `history` array
    } catch (error) {
      console.error('Error fetching price history:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { title: 'Date', dataIndex: 'calculated_at', key: 'calculated_at' },
    { title: 'Adjusted Price (ARS)', dataIndex: 'adjusted_selling_price_ars', key: 'adjusted_selling_price_ars' },
    { title: 'Discount (%)', dataIndex: 'discount_percentage', key: 'discount_percentage' },
    { title: 'ROI (%)', dataIndex: 'roi_percentage', key: 'roi_percentage' },
  ];

  return (
    <Modal
      title={`Price History for Product ${productId}`}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Table
        columns={columns}
        dataSource={priceHistory}
        rowKey="calculated_at"
        loading={loading}
      />
    </Modal>
  );
};

export default PriceHistoryModal;
