// src/context/CartContext.js
import React, { createContext, useReducer, useContext, useEffect } from 'react';
import {
    syncCart,
    fetchCart,
    addItemToCart,
    removeItemFromCart,
    updateItemQuantity,
    clearCart,
} from '../services/cartService';

// Create a Cart Context
const CartContext = createContext();

// Define the initial state for the cart
const initialState = {
    items: [],
    totalItems: 0,
    totalCost: 0,
};

// Cart Reducer
const cartReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_ITEM': {
            const existingItem = state.items.find((item) => item.id === action.payload.id);

            let updatedItems;
            if (existingItem) {
                updatedItems = state.items.map((item) =>
                    item.id === action.payload.id
                        ? { ...item, quantity: item.quantity + action.payload.quantity }
                        : item
                );
            } else {
                updatedItems = [...state.items, action.payload];
            }

            const totalItems = updatedItems.reduce((sum, item) => sum + item.quantity, 0);
            const totalCost = updatedItems.reduce((sum, item) => sum + item.quantity * item.price, 0);

            return { ...state, items: updatedItems, totalItems, totalCost };
        }

        case 'REMOVE_ITEM': {
            const updatedItems = state.items.filter((item) => item.id !== action.payload.id);

            const totalItems = updatedItems.reduce((sum, item) => sum + item.quantity, 0);
            const totalCost = updatedItems.reduce((sum, item) => sum + item.quantity * item.price, 0);

            return { ...state, items: updatedItems, totalItems, totalCost };
        }

        case 'UPDATE_QUANTITY': {
            const updatedItems = state.items.map((item) =>
                item.id === action.payload.id
                    ? { ...item, quantity: action.payload.quantity }
                    : item
            );

            const totalItems = updatedItems.reduce((sum, item) => sum + item.quantity, 0);
            const totalCost = updatedItems.reduce((sum, item) => sum + item.quantity * item.price, 0);

            return { ...state, items: updatedItems, totalItems, totalCost };
        }

        case 'SET_CART': {
            // Ensure `items` is always an array
            const items = action.payload.items || [];
            const totalItems = items.reduce((sum, item) => sum + item.quantity, 0);
            const totalCost = items.reduce((sum, item) => sum + item.quantity * item.price, 0);

            return { ...state, items, totalItems, totalCost };
        }

        case 'CLEAR_CART':
            return initialState;

        default:
            return state;
    }
};

// Cart Provider Component
export const CartProvider = ({ children }) => {
    const [cart, dispatch] = useReducer(cartReducer, initialState);

    useEffect(() => {
        const initializeCart = async () => {
            try {
                const localCart = JSON.parse(localStorage.getItem('cart') || '[]');
                if (localCart.length > 0) {
                    dispatch({ type: 'SET_CART', payload: { items: localCart } });

                    // Sync local cart to backend
                    await syncCart(localCart);
                    localStorage.removeItem('cart');
                } else {
                    const { items } = await fetchCart();
                    dispatch({ type: 'SET_CART', payload: { items } });
                }
            } catch (error) {
                console.error('Failed to initialize cart:', error);
            }
        };

        initializeCart();
    }, []);

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart.items));
    }, [cart.items]);

    return (
        <CartContext.Provider value={{ cart, dispatch }}>
            {children}
        </CartContext.Provider>
    );
};

// Custom Hook to use Cart Context
export const useCart = () => {
    return useContext(CartContext);
};
