import React, { useEffect, useState } from 'react';
import { fetchCategories } from '../../services/productService';
import CategoryCard from './CategoryCard'; // Import new component
import './CategoryList.css';

// Utility function to humanize category names (if needed on the client side)
const humanizeCategoryName = (name) => {
  return name
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize each word
    .replace(/\bAnd\b/i, '&'); // Replace "And" with "&" (case-insensitive)
};

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        console.log("Fetching categories...");
        const categoryData = await fetchCategories();
        console.log("Raw categories fetched:", categoryData);

        // Humanize the category names before setting the state
        const transformedCategories = categoryData.map((category) => ({
          ...category,
          name: humanizeCategoryName(category.name),
        }));

        console.log("Transformed categories:", transformedCategories);

        setCategories(transformedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    loadCategories();
  }, []);

  if (loading) {
    return <div className="category-loading">Loading categories...</div>;
  }

  return (
    <div className="category-list">
      {categories.length > 0 ? (
        categories.map((category) => (
          <CategoryCard key={category.id} category={category} /> // Pass data to CategoryCard
        ))
      ) : (
        <div>No categories available.</div>
      )}
    </div>
  );
};

export default CategoryList;
