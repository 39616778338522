import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// Context Providers
import { AuthProvider } from './context/AuthContext';
import { CartProvider } from './context/CartContext';

// Pages and Components
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import Dashboard from './components/admin/Dashboard';
import LoginForm from './components/login/LoginForm';
import ProductManagement from './components/admin/ProductManagement';
import PriceDynamics from './components/admin/PriceDynamics';
import ProductDetail from './components/product/ProductDetail';
import ProtectedRoute from './components/protect/ProtectedRoute';
import CookieBanner from './components/common/CookieBanner';
import CartPage from './pages/CartPage'; // Import CartPage

function App() {
    return (
        <AuthProvider>
            <CartProvider>
                <div className="App">
                    <Router>
                        <CookieBanner /> {/* Display the CookieBanner */}
                        <main>
                            <Routes>
                                {/* Public Routes */}
                                <Route path="/" element={<HomePage />} />
                                <Route path="/products" element={<ProductPage />} />
                                <Route path="/login" element={<LoginForm />} />
                                <Route path="/product/:productId" element={<ProductDetail />} />
                                <Route path="/cart" element={<CartPage />} /> {/* Add CartPage route */}

                                {/* Protected Routes */}
                                <Route element={<ProtectedRoute />}>
                                    <Route path="/dashboard" element={<Dashboard />} />
                                    <Route path="/admin/product-management" element={<ProductManagement />} />
                                    <Route path="/admin/pricing-dynamics" element={<PriceDynamics />} />
                                    {/* Add more protected routes as needed */}
                                </Route>

                                {/* Fallback route for non-matching paths */}
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                        </main>
                    </Router>
                </div>
            </CartProvider>
        </AuthProvider>
    );
}

export default App;
