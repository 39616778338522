import React, { useState, useEffect } from 'react';
import { Table, Button } from 'antd';
import { fetchPriceDynamicsData } from '../../services/pricingService';
import DynamicPricingModal from './DynamicPricingModal';
import PriceHistoryModal from './PriceHistoryModal'; // New modal for price history
import './PriceDynamics.css';

const PriceDynamics = () => {
  const [pricingData, setPricingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDynamicPricingModalVisible, setIsDynamicPricingModalVisible] = useState(false);
  const [isPriceHistoryModalVisible, setIsPriceHistoryModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    loadPricingData();
  }, []);

  const loadPricingData = async () => {
    try {
      setLoading(true);
      const data = await fetchPriceDynamicsData();

      // Extract only the most recent price for each product
      const recentPrices = Array.from(
        data.price_dynamics.reduce((map, item) => {
          // Use product_id as the key and keep the most recent price
          if (!map.has(item.product_id) || map.get(item.product_id).calculated_at < item.calculated_at) {
            map.set(item.product_id, item);
          }
          return map;
        }, new Map()).values()
      );

      setPricingData(recentPrices);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching pricing data:', error);
      setLoading(false);
    }
  };

  const handleOpenDynamicPricingModal = (record) => {
    setSelectedProduct(record);
    setIsDynamicPricingModalVisible(true);
  };

  const handleOpenPriceHistoryModal = (record) => {
    setSelectedProduct(record);
    setIsPriceHistoryModalVisible(true);
  };

  const handleModalClose = () => {
    setIsDynamicPricingModalVisible(false);
    setIsPriceHistoryModalVisible(false);
    setSelectedProduct(null);
  };

  const columns = [
    { title: 'Product ID', dataIndex: 'product_id', key: 'product_id' },
    { title: 'Product Name', dataIndex: 'product_name', key: 'product_name' },
    { title: 'Adjusted Price (ARS)', dataIndex: 'adjusted_selling_price_ars', key: 'adjusted_selling_price_ars' },
    { title: 'Discount (%)', dataIndex: 'discount_percentage', key: 'discount_percentage' },
    { title: 'ROI (%)', dataIndex: 'roi_percentage', key: 'roi_percentage' },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button onClick={() => handleOpenDynamicPricingModal(record)}>
            Update Pricing
          </Button>
          <Button onClick={() => handleOpenPriceHistoryModal(record)}>
            View History
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="price-dynamics">
      <h2>Price Dynamics</h2>
      <Table
        columns={columns}
        dataSource={pricingData}
        rowKey="product_id"
        loading={loading}
      />
      {selectedProduct && (
        <>
          <DynamicPricingModal
            visible={isDynamicPricingModalVisible}
            onCancel={handleModalClose}
            productId={selectedProduct.product_id}
            onSubmit={loadPricingData}
            isNewEntry={!selectedProduct.adjusted_selling_price_ars}
          />
          <PriceHistoryModal
            visible={isPriceHistoryModalVisible}
            onCancel={handleModalClose}
            productId={selectedProduct.product_id}
          />
        </>
      )}
    </div>
  );
};

export default PriceDynamics;
