// pricingService.js
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to get auth or guest token
const getAuthToken = async () => {
  let token = localStorage.getItem('token');
  let guestToken = localStorage.getItem('guestToken');

  if (!token && !guestToken) {
    try {
      const response = await axios.get(`${BASE_URL}/auth/guest-token`);
      guestToken = response.data.access_token;
      localStorage.setItem('guestToken', guestToken);
    } catch (error) {
      console.error("Failed to retrieve guest token:", error);
      return null;
    }
  }

  return token || guestToken;
};

// Fetch all price dynamics data
export const fetchPriceDynamicsData = async () => {
  try {
    const token = await getAuthToken();
    const response = await axios.get(`${BASE_URL}/products/price-dynamics`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch price dynamics data:", error);
    throw error;
  }
};

// Update product price in price dynamics
export const updateDynamicPricing = async (productId, priceData) => {
  try {
    const token = await getAuthToken();
    const response = await axios.post(
      `${BASE_URL}/admin/update-price`,
      { ...priceData, product_id: productId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update dynamic pricing:", error);
    throw error;
  }
};

// Fetch detailed pricing information for a specific product
export const fetchProductPricingDetails = async (productId) => {
  try {
    const token = await getAuthToken();
    const response = await axios.get(`${BASE_URL}/admin/dynamic-pricing/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch product pricing details:", error);
    throw error;
  }
};
