import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { useCart } from '../../../context/CartContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'; // Font Awesome cart icon

const CartIcon = () => {
    const { cart } = useCart();

    return (
        <Link to="/cart" className="cart-icon"> {/* Wrap with Link */}
            <span className="cart-count">{cart.totalItems}</span>
            <FontAwesomeIcon icon={faCartShopping} size="lg" />
        </Link>
    );
};

export default CartIcon;
