import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Utility to get a valid token (auth or guest)
const getAuthToken = async () => {
  let token = localStorage.getItem('token');
  let guestToken = localStorage.getItem('guestToken');

  if (!token && !guestToken) {
    console.warn("No auth or guest token found. Attempting to retrieve guest token...");
    try {
      const response = await axios.get(`${BASE_URL}/auth/guest-token`);
      guestToken = response.data.access_token;
      localStorage.setItem('guestToken', guestToken);
      console.log("Guest token retrieved and saved.");
    } catch (error) {
      console.error("Failed to retrieve guest token:", error);
      return null;
    }
  }

  return token || guestToken; // Return the available token
};

// Generic Axios request wrapper
const makeRequest = async (method, endpoint, data = null, params = null) => {
  try {
    const token = await getAuthToken();
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    const response = await axios({
      method,
      url: `${BASE_URL}${endpoint}`,
      headers,
      data,
      params,
    });

    console.log(`Response from ${endpoint}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error during request to ${endpoint}:`, error);
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    }
    throw error; // Rethrow error for handling in the caller
  }
};



// Fetch filtered products by category

export const fetchFilteredProductsByCategory = async ({ categoryId, page = 1, pageSize = 12 }) => {
  const params = { category_id: categoryId, page, page_size: pageSize };
  return await makeRequest('get', '/products/filter-by-category', null, params); // Correct endpoint
};




// Fetch paginated and filtered products for staff product management
export const fetchProducts = async ({ page = 1, pageSize = 10, search = '' } = {}) => {
  const params = { page, page_size: pageSize, search };
  return await makeRequest('get', '/admin/products', null, params);
};

// Fetch individual product details by ID
export const fetchProductDetails = async (productId) => {
  try {
    const response = await makeRequest('post', '/admin/product-detail', { product_id: productId });
    if (response.attributes && typeof response.attributes === 'string') {
      response.attributes = JSON.parse(response.attributes); // Ensure attributes is parsed as JSON
    }
    return response;
  } catch (error) {
    console.error('Failed to fetch product details:', error);
    throw error;
  }
};

// Add or Update Product with Images
export const addOrUpdateProduct = async (productData, files, primaryImageIndex = 0, imageOrder = {}) => {
  const token = await getAuthToken();
  const formData = new FormData();

  // Append product data excluding 'attributes'
  for (const key in productData) {
    if (key === 'attributes') continue; // Skip 'attributes' for now
    formData.append(key, typeof productData[key] === 'object' ? JSON.stringify(productData[key]) : productData[key]);
  }

  // Append 'attributes' as JSON string
  if (productData.attributes) {
    formData.append('attributes', JSON.stringify(productData.attributes));
  }

  // Append files, marking primary image and order
  if (files && files.length > 0) {
    files.forEach((file, index) => {
      formData.append(`file_${index}`, file);
      formData.append(`file_order_${index}`, imageOrder[index] || index); // Send order data
      if (index === primaryImageIndex) {
        formData.append('primary_image_index', index); // Specify primary image
      }
    });
  }

  return await makeRequest('post', '/admin/add-or-update-product', formData);
};

// Update product price
export const updateProductPrice = async (productId, productData) => {
  return await makeRequest('post', '/admin/update_price', { ...productData, product_id: productId });
};

// Fetch product categories
export const fetchCategories = async () => {
  return await makeRequest('get', '/category/api/category');
};

// Fetch product types
export const fetchProductTypes = async () => {
  return await makeRequest('get', '/admin/get-product-types');
};

// Fetch product type schema with attributes
export const fetchProductTypeSchema = async () => {
  return await makeRequest('get', '/admin/get-product-type-schema');
};

// Delete an image asset
export const deleteImage = async (imageId) => {
  return await makeRequest('delete', `/products/delete-asset/${imageId}`);
};

// Fetch comprehensive product details by ID
export const fetchComprehensiveProductDetails = async (productId) => {
  return await makeRequest('get', `/products/comprehensive-product-detail/${productId}`);
};
